<template>
  <div>

    <div class="hip elementor-element elementor-element-ae314fc elementor-widget elementor-widget-gva-listing-search-form">
      <div class="elementor-widget-container">
        <div class="gva-element-gva-listing-search-form gva-element">
          <div class="lt-listing-search-form style-2">
            <form class="lt-search-form-main">
              <!-- hola -->
              <div class="search-form-content" style="padding-right: 0;">
                <div class="search-form-fields clearfix cols-2 has_search_keyword">
                  <div class="search_keywords">
                    <div class="content-inner selects-row">
                      <i class="icon fa-solid fa-globe"></i>
                        <input type="text" v-model="search" name="search" class="lt-search-keyword-autocomplete"
                          placeholder="Search" autocomplete="off" />

                      
                      
                    </div>
                  </div>
                  <div class="filters">
                    <div style="display: none">
                      <select v-model="country">
                        <option v-for="country in countries">{{country}}</option>
                      </select>
                    </div>
                    <select style="90% !important" v-model="industry">
                        <option v-for="ind in industries">{{ind}}</option>
                      </select>
                  </div>
                  
                </div>
                <div class="form-action" style="display:none">
                  <button class="btn-theme btn-action" type="submit">
                    <i class="fi flaticon-magnifying-glass"></i>
                    Search </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-show="openModal" class="modal-wrapper hip-modal">
      <div class="modal-content">
        <div class="headshoot">
          <img :src="selected.photo" />
        </div>
        <div class="content">
          <div class="title flex flex-col">
            <h2 style="margin-bottom: 10px">
              {{ selected.name }}
              <a v-if="selected.linkedin != null" :href="selected.linkedin"><img src="/profiles/linkedin.png"
                style="width: 21px; height: 21px; display: inline-block;" /></a>
            </h2>
            
            <div class="flex" style="align-items: center;">
              <a @click="openModal = false" style="">&times;</a>
            </div>
          </div>
          <h3 style="font-size: 1rem; font-weight: 400; margin-top: 0;margin-bottom: 5px;">{{selected.city}}</h3>
          <h3 style="font-size: 1rem; font-weight: 400; margin-top: 0;"><span style="font-weight: 600;">Latinx Heritage:</span> {{selected.country}}</h3>
          <h3 style="font-size: 1.4rem; margin-top: 0; font-weight: 500;">
          
          <a v-if="selected.link != null" :href="selected.link" target="_blank">
              {{ selected.company }}
          </a><span v-if="selected.link == null">{{ selected.company }}</span>, {{ selected.title }} </h3>
          <div style="display: flex; flex-direction: row;">
            <!-- company logo -->
            <div v-if="selected.logo != null" style="width: 4.5rem;flex-shrink: 0;"><img style="width: 4rem; height: auto;" :src="selected.logo" /></div>
            <!-- starup description -->
            <div class="text-container" v-html="selected.startup"></div>  
          </div>
          <hr style="width: 100%; border-top: 1px solid red; padding: 5px " />
          <div class="text-container" v-html="selected.founder"></div>
        </div>
      </div>
    </div>
    <div class="elementor-element elementor-element-dae4927 elementor-widget elementor-widget-gva-listings-banner-group"
      data-id="dae4927" data-element_type="widget" data-widget_type="gva-listings-banner-group.default">
      <div class="elementor-widget-container">
        <div class="gva-element-gva-listings-banner-group gva-element">
          <div class="gsc-listings-banner-group layout-grid style-2">
            <div class="lg-block-grid-3 md-block-grid-3 sm-block-grid-2 xs-block-grid-1 xx-block-grid-1">

              <!-- FirstItem -->
              <div v-for="item in filtered" v-bind:key="item['Field 1']" class="item listings-banner-item">
                <div @click="show(item)" style="cursor: pointer;" class="listings-banner-item-content">

                  <div class="banner-image">
                    <img :src="`${item.photo != null ? item.photo : '/profiles/placeholder.jpg'}`"
                      :alt="item['Field 1']">
                  </div>

                  <div class="banner-content">
                    <span v-if="item.logo != null" class="box-icon" style="overflow: hidden; padding-top: 0;">
                      <img :src="`${item.logo}`" :alt="item['Field 1']" class="list-icon" style="">
                    </span>
                    <h3 class="title">
                      {{ item['Field 1'] }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import data from '../data.json';

export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      country: 'All countries',
      industry: 'All industries',
      value: '',
      countries: [],
      options: [
        {
          value: 0,
          label: "Grape"
        },
        {
          value: 1,
          label: "Watermelon"
        },
      ],
      openModal: false,
      selected: {
        linkedin: null,
        link: null,
        name: '',
        bio: '',
        country: '',
        photo: '', logo: '', company: '', title: ''
      },
      search: "",
      data: data
    };
  },
  mounted() {
    axios.get('https://100rising.inicio.ventures/profiles/data.json')
      // axios.get('http://vue-parcel-starter-main.test/profiles/data.json')
      .then(response => {
        this.data = response.data.map(item => {
          return {
            photo: `/${item.photo}`,
            ...item
          }
          this.buildSelects()
        })
      })
    this.data = this.data.map(item => {
      return {
        photo: `/${item.photo}`,
        ...item
      }
    })
    console.log(this.data[0]);
    this.buildSelects()
  },
  methods: {
    buildSelects() {
      this.countries = this.data.map(e => e['Field 8']).filter(function(item, pos, a) {
         return a.indexOf(item) == pos;
      }).sort()
      this.countries = ['All countries', ...this.countries]

      // industry
      this.industries = this.data.map(e => e['Field 6']).filter(function(item, pos, a) {
         return a.indexOf(item) == pos;
      }).sort()
      this.industries = ['All industries', ...this.industries]
    },
    show(item) {
      this.selected.name = item['Field 1']
      this.selected.company = item['Field 3']
      this.selected.title = item['Field 2']
      this.selected.linkedin = item['Field 5']
      this.selected.link = item['Field 4'] != undefined ? item['Field 4'] : null
      this.selected.photo = item.photo
      this.selected.Bio = item.Bio
      this.selected.bio = item['Field 6']
      this.selected.logo = item['logo']
      this.selected.country = item['Field 8']
      this.selected.city = item.city
      this.selected.startup = item['Startup']
      this.selected.founder = item['Founder']
      console.log(item);
      console.log(this.selected);
      this.openModal = true
    },
    filter(string, data) {
      // filter data by string
      let response = data;
      if (string != null && string != '') {
        response = data.filter(e => {
          // console.log(e)
          // console.log(e['Field 1'])
          return e['Field 1'] != undefined &&
            (
              e['Field 1'].toLowerCase().includes(string.toLowerCase()) ||
              e['Field 3'].toLowerCase().includes(string.toLowerCase())
            )
        });
      };
      if( this.country != 'All countries') {
        response = response.filter( e => {
          return e['Field 8'] == this.country
        })
      }
      console.log(response);
      if( this.industry != 'All industries') {
        response = response.filter( e => {
          return e['Field 6'] != undefined ? e['Field 6'].trim() == this.industry : false
        })
        console.log(response);
      }
      return response;
      
    },

    photoPath(name) {
      return name != undefined ? name.replace(' ', '') : ''
    }
  },
  watch: {
    
  },
  computed: {
    filtered() {
      return this.filter(this.search, this.data);
    }
  }
};
</script>

<style lang="scss">
.hip-modal a {
  color: #FF344F;
}
.hip .lt-listing-search-form .search-form-content .search-form-fields.cols-2.has_search_keyword > div.search_keywords {
  width: 50%;
}
.hip .lt-listing-search-form .search-form-content {
  padding-right: 0px;
  width: 100%;
}
.hip .lt-listing-search-form .search-form-content .search-form-fields.cols-2.has_search_keyword > div:not(.search_keywords) {
  width: 40%;
}
.hip .filters {
  width: 40%;
  display: block;
  padding-top: 0.3rem;
}
.hip .lt-listing-search-form .search-form-content .search-form-fields {
  width: 100%;
  justify-content: space-between;
}
.selects-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.modal-wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: hsl(0deg 0% 0% / 64%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.elementor img.list-icon {
  object-fit: contain;
  width: 80px;
  height: 80px;
}

.modal-items-links {
  display: flex;
  align-items: center;
}

.modal-wrapper .modal-content {
  min-height: 20vh;
  max-width: 90vw;
  width: 90vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: scroll;
  margin-top: 2rem;
}

.modal-content .content {
  flex: 1 0;
  padding: 1rem;

}

.modal-content .content .title {
  width: 100%;
  justify-content: space-between;
  // position: fixed;
  // background-color: white;
  // max-width: 82vw;
}

.modal-content .content .title a {
  background-color: white;
  padding: 0 0.5rem;
  margin: 0.5rem;
  height: 2rem;
  border-radius: 4px;
}

.modal-content .content .title a:hover {
  background-color: rgb(224, 224, 224);
}

.headshoot {
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.text-container {
  padding: 0;
}
.text-container p {
  padding-bottom: 1rem;
}

.headshoot img {
  width: 100%;
  object-fit: contain;
  height: auto;
  border-top-left-radius: 4px;
}

@media (min-width: 720px) {
  .modal-wrapper .modal-content {
    max-width: 60vw;
    width: 60vw;
    flex-direction: row;
  }

  .headshoot {
    width: 30%;
    display: inline-block;
  }

  .modal-content .content .title {
    max-width: 40vw;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: row;
}
</style>
